
.section-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  position: relative;
}

.section {
  height: 97vh;
  width: 47vw;
  border-radius: 5%;
  position: relative;
  z-index: 0; /* Ensure this is lower */
}

.section-r {
  background-color: rgba(255, 192, 0, 0.3);
}

.section-l {
  background-color: rgba(72, 116, 196, 0.3);
}

.line {
  width: 94%;
  position: absolute;
  height: 7px;
  background-color: rgba(72, 116, 196);
  z-index: 1; /* Ensure this is lower */
  margin-bottom: 4.5rem;
}

.up {
  margin-bottom: 7.5rem;
  width: 150px;
  height: 25px;
  color: white;
  padding-top: 5px;
}
.up p {
  text-align: center;
}

.down p {
  text-align: center;
}

.down {
  margin-top: 3rem;
  width: 150px;
  height: 25px;
  color: white;
  padding-top: 5px;
}

.lower-section {
  border: 1px solid rgba(255, 255, 255, 0.2);
  /* bottom: 10rem; */
  background: rgba(72, 116, 196, 0.4); /* Slightly darker and more opaque background */
  backdrop-filter: blur(10px); /* Blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: light shadow for depth */
  border-radius: 10px; /* Optional: rounded corners */
  position: relative;
  z-index: 0; /* Ensure this is lower */
}

.device-state {
  background: rgba(72, 116, 196, 0.4);
  width: 60%;
  position: absolute;
  right: 19px;
  bottom: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}

.device-state .span-l {
  width: 40%;
}

.span-r {
  background-color: transparent;
  color: inherit;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  width: 60%;
}

.span-r:focus {
  outline: none;
}

.lower-r, .lower-l, .lower-m {
  position: absolute;
}

.lower-r {
  height: 33%;
  right: 20px;
  width: 60%;
  bottom: 7.4rem;
}

.lower-l {
  height: 51%;
  left: 20px;
  width: 60%;
  bottom: 10px;
}

.lower-m {
  width: 30%;
  bottom: 18px;
  height: 40%;
  z-index: 1;
}

hr {
  margin: 0;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}

.lower-section p {
  font-size: 12px;
}

.device-state p {
  font-size: 12px;
}

p {

  margin: 0.5px;
  font-size: 14px;
}

.lower-buttons {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  position: absolute;
  bottom: 10px;
  width: 100%;
}
.middle-buttons {

  margin-bottom: 0;
}
.share-container {
  display: flex;
  justify-content: center;
}
.share {
  /* margin-top: 5rem; */
  width: 100px;
  background-color: rgb(72, 196, 97);
  color: white;
  bottom: 10px;
  margin-top: 10px;

}

.calender {
  background-color: red !important;
}

.upper-section {
  width: 96%;
  height: 44%;
  margin: 10px;
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
  z-index: 1; /* Ensure this is higher */
}

.input-container {
  width: 30%;
}

.input {
  border: 1px solid rgba(72, 116, 196);
  width: 100%;
  height: 57%;
  background-color: rgba(72, 116, 196);
  padding: 0px 10px;
  position: relative;
  margin-top: 0.7rem;
}

.table {
  width: 65%;
  height: 57%;
  z-index: 1; /* Ensure this is higher */
}

select {
  height: 18px;
  border-radius: 5px;
  border: none;
}

select:focus {
  outline: none;
}

input {
  height: 18px;
  border-radius: 5px;
  border: none;
}

input:focus {
  outline: none;
}

button {
  height: 25px;
  background-color: rgba(255, 192, 0);
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.input-button {
  position: absolute;
  bottom: 7px;
  left: 10px;
  width: 90%;
  cursor: pointer;
}

label {
  color: white;
  margin-bottom: 0.2rem;
  margin-top: 0.4rem;
  font-size: 13px;
}

.span-l {
  display: inline-block;
  font-weight: bold;
  width: 28%;
  margin-left: 10px;
}
.disabled {
  background-color: rgba(255, 0, 0, 0.41) !important; /* Change background color */
  cursor: not-allowed; /* Change cursor */
  /* Add other styles as needed */
}

.status {
  display: inline-flex;
  justify-content: center;
  background-color: rgba(21, 255, 0, 0.499);
  width: 40px;
  border-radius: 10px;
}

.lower-buttons label {
  color: black;
}

.lower-buttons button {
  margin-top: 1rem;
}

.header {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
}

.header-section {
  height: 20px;
  width: 150px;
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
}

.header-section p {
  margin-left: 5px;
  margin-top: 0;
  font-size: 14px;
  font-weight: bold;
}
